<template>
  <module-container title="游戏情报" border="border" url="/news" :class="{ active: isSearch }">
    <template #icon>
      <img src="../../../assets/images/news-icon.png" alt="" />
    </template>
    <template v-if="loginUid > 0 && loginUser.is_super && device.desktop" #title-right>
      <el-button type="primary" @click="goEdit">发情报</el-button>
    </template>
    <div :class="['list-box', classBase + 'list-box']">
      <template v-for="(item, index) in _list">
        <template v-if="!isSearch">
          <a
            :key="index"
            v-if="index < 5"
            :href="getJumpWikiDetailUrl(item.game_alias, item.game_id, item.id)"
            :target="device.desktop ? '_blank' : '_self'"
            class="item"
          >
            <template v-if="isDetail">
              <img :src="item.thumb.split(',')[0]" class="cover" />
            </template>
            <template v-else>
              <img :src="item.thumb.split(',')[0]" class="cover" />
            </template>
            <div class="item-r">
              <div class="title">{{ item.title }}</div>
              <div class="bottom-row">
                <div v-if="false" class="user">
                  <img :src="item.user.avatar" alt="" />
                  <span>{{ item.user.username }}</span>
                </div>
                <div class="row-r">
                  {{ $dayjs.unix(item.created_at).format("YYYY年MM月DD日") }}
                </div>
              </div>
            </div>
          </a>
        </template>
        <template v-else>
          <a
            :key="index"
            :href="getJumpWikiDetailUrl(item.game_alias, item.game_id, item.id)"
            :target="device.desktop ? '_blank' : '_self'"
            class="item"
          >
            <template v-if="isDetail">
              <img :src="item.thumb.split(',')[0]" class="cover" />
            </template>
            <template v-else>
              <img :src="item.thumb.split(',')[0]" class="cover" />
            </template>
            <div class="item-r">
              <div class="title">{{ item.title }}</div>
              <div class="bottom-row">
                <div v-if="false" class="user">
                  <img :src="item.user.avatar" alt="" />
                  <span>{{ item.user.username }}</span>
                </div>
                <div class="row-r">
                  {{ $dayjs.unix(item.created_at).format("YYYY年MM月DD日") }}
                </div>
              </div>
            </div>
          </a>
        </template>
      </template>
    </div>
  </module-container>
</template>

<script>
import ModuleContainer from "./moduleContainer";
// import SvgIcon from "../../../components/SvgIcon/svgIcon";
// import ScrollComp from "../../../components/Scroll";
import { apiWwwNewsList } from "../../../api/wwwApi";
import { getJumpWikiDetailUrl } from "../../../plugins/util";
import { mapGetters } from "vuex";

export default {
  name: "newsContainer",
  mixins: [],
  props: {
    list: {
      type: Array,
      default: () => []
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    isSearch: {
      type: Boolean,
      default: false
    }
  },
  model: {},
  components: {
    // ScrollComp,
    // SvgIcon,
    ModuleContainer
  },
  data() {
    return {
      pagination: {
        limit: 5,
        page_no: 1,
        page_total: 2,
        total: 0
      },
      newsList: [],
      border: true
    };
  },
  computed: {
    ...mapGetters(["loginUid", "loginUser"]),
    _list() {
      return this.list.concat(this.newsList);
    }
  },
  methods: {
    getJumpWikiDetailUrl,
    goEdit() {
      this.$router.push("/editor?type=8");
    },
    getList() {
      let { pagination, $refs } = this;
      if (pagination.page_no >= pagination.page_total) {
        $refs.scrollComp.loadStatus = "end";
        return;
      }
      ++pagination.page_no;
      $refs.scrollComp.loadStatus = "loading";
      apiWwwNewsList(pagination)
        .then(res => {
          this.newsList = this.newsList.concat(res.data);
          this.pagination = res.meta.pagination;
        })
        .finally(_ => {
          $refs.scrollComp.loadStatus = "";
        });
    }
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.list-box {
  display: flex;
  flex-wrap: wrap;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    background: #f9f9f9;
    width: 432px;
    height: 94px;
    padding: 6px;
    margin: 0 8px 12px 0;

    &:nth-child(2n) {
      margin-right: 0;
    }

    .cover {
      height: 100%;
      width: 107px;
      object-fit: cover;
      border-radius: 4px;
    }

    .item-r {
      width: calc(100% - 107px);
      padding: 4px 8px 4px 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        height: 40px;
        font-size: 14px;
        font-weight: 500;
        color: #171717;
        line-height: 21px;
        overflow: hidden;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      .bottom-row {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #999;

        .user {
          display: flex;
          align-items: center;

          img {
            width: 16px;
            height: 16px;
            object-fit: cover;
            border: 1px solid #909090;
            margin-right: 8px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.wap-list-box {
  .item {
    width: 100%;
    height: 72px;
    margin-right: 0;
    background: none;
    padding: 0;
    margin-bottom: 24px;
    &:nth-child(1) {
      margin-top: 15px;
    }
    &:nth-child(5) {
      margin-bottom: 15px;
    }
  }
}
</style>
