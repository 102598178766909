import service from "@/plugins/request";

// 推荐帖子列表
export function apiContentList(params) {
  return service({
    url: "/v1/content/list",
    method: "get",
    params: params
  });
}

// 帖子列表
export function apiContentTopList(params) {
  let url = "/v1/content/pageList";
  if (params.keyword && params.keyword.length) {
    url = "/v1/content/searchArticle";
  }
  return service({
    url: url,
    method: "get",
    params: params
  });
}

// 删除帖子
export function apiContentDelete(params) {
  return service({
    url: "/v1/protected/content/delete",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 禁止编辑
export function apiContentDisableComments(params) {
  return service({
    url: "/v1/protected/content/disableComments",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 编辑帖子
export function apiContentEdit(params) {
  let url = "/v1/protected/content/add";
  if (params.id) {
    url = "/v1/protected/content/edit";
  }
  return service({
    url: url,
    method: "post",
    data: params,
    successToast: false,
    errorToast: true
  });
}

// 添加游客帖子
export function apiSightseerAdd(params) {
  return service({
    url: "/v1/content/sightseer/add",
    method: "post",
    data: params,
    successToast: false,
    errorToast: false
  });
}

// 更换文章绑定
export function apiContentBind(params) {
  return service({
    url: "/v1/protected/entry/bindContent",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 创建模块
export function apiContentAddModel(params) {
  return service({
    url: "/v1/protected/content/addModel",
    method: "post",
    data: params
  });
}

// 文章详情
export function apiContentDetail(params) {
  return service({
    url: "/v1/content/detail/" + params.id,
    method: "get",
    params: ""
  });
}

// 帖子，评论点赞
export function apiContentLike(params) {
  return service({
    url: "/v1/protected/content/like",
    method: "post",
    data: params
  });
}

// 文章置顶
export function apiContentTop(params) {
  return service({
    url: "/v1/protected/content/topping",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 文章下热门
export function apiContentIgnoreHot(params) {
  return service({
    url: "/v1/protected/content/ignore-hot",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 保存模块
export function apiModelAdd(params) {
  return service({
    url: "/v1/protected/model/add",
    method: "post",
    data: params
  });
}

// 图鉴词条列表
export function apiEntryTypeList(params) {
  return service({
    url: "/v1/content/entryTypeList",
    method: "get",
    params: params
  });
}

// 插入模块
export function apiInsertModel(params) {
  return service({
    url: "/v1/protected/content/insertModel",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 保存模版
export function apiTemplateSave(params) {
  let url = "/v1/protected/template/add";
  if (params.id && params.id > 0) {
    url = "/v1/protected/template/edit";
  }
  return service({
    url: url,
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 删除模版
export function apiTemplateDel(params) {
  return service({
    url: "/v1/protected/template/delete",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 删除模版
export function apiTemplateList(params) {
  return service({
    url: "/v1/protected/template/list",
    method: "get",
    params: params
  });
}

// 历史记录
export function apiLogList(params) {
  return service({
    url: "/v1/content/logList",
    method: "get",
    params: params
  });
}

// 历史记录
export function apiVersionDetail(params) {
  return service({
    url: "/v1/content/versionDetail",
    method: "get",
    params: params
  });
}

// 历史记录回滚
export function apiRollBack(params) {
  return service({
    url: "/v1/protected/content/rollBack",
    method: "post",
    data: params,
    successToast: true
  });
}

// 获取文章共创者
export function apiGetDetailContributor(params) {
  return service({
    url: "/v1/content/contributor",
    method: "get",
    params: params
  });
}

// 投票
export function apiContentVote(params) {
  return service({
    url: "/v1/protected/content/vote",
    method: "post",
    data: params
  });
}

// 开启关闭词条关联
export function apiSetEntryAuto(params) {
  return service({
    url: "/v1/protected/content/auto-entry",
    method: "post",
    data: params
  });
}

// 获取小程序跳转地址
export function apiGetWechatScheme(params) {
  return service({
    url: "/v1/protected/wechat/query-scheme",
    method: "post",
    data: params
  });
}
