import service from '@/plugins/request';

// K站分类列表
export function apiRecommendList (params) {
  return service({
    url:'/v1/gameRecommend/pageList',
    method:'get',
    params: params
  })
}
// K站推荐分类
export function apiRecommendType (params) {
  return service({
    url:'/v1/gameRecommendClassify/list',
    method:'get',
    params: params
  })
}
// 视频播放次数
export function apiVideoNum (params) {
  return service({
    url:'/v1/gameVideo/addPlayNum',
    method: 'get',
    params: params
  })
}

// 大首页搜索游戏
export function apiIndexSearch (params) {
  return service({
    url: '/v1/gameInfo/pageList',
    method: 'get',
    params: params
  })
}

// www大首页
export function apiWwwIndex (params) {
  return service({
    url: '/v1/index/index',
    method: 'get',
    params: params
  });
}

// 情报列表
export function apiWwwNewsList (params) {
  return service({
    url: '/v1/index/newsList',
    method: 'get',
    params: params
  });
}

// app配置信息
export function apiAppConfig (params) {
  return service({
    url: '/v1/app/info',
    method: 'get',
    params: params
  });
}

// app配置信息更新
export function apiAppConfigUpdate (params) {
  return service({
    url: '/v1/protected/app/editPackage',
    method: 'post',
    params: params,
    successToast: true
  });
}

// 发私信
export function apiAdminPersonalLetter (params) {
  return service({
    url: '/v1/protected/user/adminPersonalLetter',
    method: 'post',
    data: params,
    successToast: true
  });
}
