import service from '@/plugins/request';

// 大首页社区列表
export function apiIndexShe (params) {
  return service({
    url: '/v1/index/contentPageList',
    method: 'get',
    params: params
  });
}

// 模块排序
export function apiModuleSort (params) {
  return service({
    url: '/v1/protected/module/sort',
    method: 'post',
    data: params,
    successToast: true,
    errorToast: true
  });
}

