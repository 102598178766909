<template>
  <div :class="['module-container', classBase + 'module-container', border, { on: isOn }]">
    <slot v-if="$slots.title" name="title"></slot>
    <div v-else-if="title" class="module-title" :class="{ active: border }">
      <div class="t-l">
        <slot name="icon"></slot>
        <span>{{ title }}</span>
      </div>
      <a :href="url" class="t-r" v-if="isMore">
        <slot name="title-right"> <span>更多</span><img src="../../../assets/images/jian-icon.png" /> </slot>
      </a>
    </div>
    <div class="module-content" :class="{ active: border }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "moduleContainer",
  mixins: [],
  props: {
    title: {
      type: String,
      default: ""
    },
    border: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ""
    },
    isMore: {
      type: Boolean,
      default: true
    },
    isOn: {
      type: Boolean,
      default: false
    }
  },
  model: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    // pageGo(){
    //   this.$router.push(this.url);
    // }
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.module-container {
  margin-bottom: 16px;
  &.active {
    .module-title {
      display: none;
    }
  }
}
.wap-module-container {
  margin-bottom: 8px;
  background: #fff;
  &.on {
    .module-title.active {
      margin: 0.0375rem;
      background: #f2f4f6;
      border-radius: 0.075rem 0.075rem 0px 0px;
      border-bottom: none;
      padding-left: 15px;
      padding-right: 20px;
    }
    .module-content {
      &.active {
        padding: 0 17px;
      }
    }
  }
  .module-title {
    padding: 14px 17px 0;
    &.active {
      border-bottom: 1px solid rgba(234, 234, 234, 0.8);
      margin-bottom: 0;
      padding: 14px 0 10px 0;
    }
  }

  .module-content {
    padding: 0 17px;
    &::-webkit-scrollbar-track {
      background: #fff;
    }
    &.active {
      padding: 0;
    }
  }
}
.wapNewBanner {
  margin-bottom: 7px;
  .module-content {
    padding: 0;
  }
}
.module-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  .t-l {
    font-size: 14px;
    color: #333333;
    display: flex;
    align-items: center;
    font-weight: 600;
    img {
      display: block;
      width: 17px;
      margin-right: 6px;
    }
  }
  .t-r {
    background: #f4f4f4;
    border-radius: 9px;
    font-size: 10px;
    padding: 2px 5px 2px 8px;
    color: #999999;
    display: flex;
    align-items: center;
    img {
      width: 9px;
      height: 9px;
      margin-left: 2px;
    }
  }
}

.module-content {
  overflow-x: auto;
  overflow-y: hidden;
}
</style>
