<template>
  <div class="wap-model-container" :class="[alias + '-wap-model-container']">
    <div v-if="title" class="model-title">
      <span class="title">{{ title }}</span>
      <div class="right-wrapper">
        <slot name="titleRight"></slot>
      </div>
    </div>
    <div class="model-content" :class="{ active: isActive }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "wapModelContainer",
  mixins: [],
  props: {
    title: {
      type: String,
      default: ""
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  model: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.wap-model-container {
  margin: 4px 0;
  border-radius: 0;

  .model-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px 0 16px;

    .title {
      line-height: 28px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .model-content {
    padding: 10px 16px;
    &.active {
      padding: 0;
    }
  }
}
.ba-wap-model-container {
  border-radius: 4px;
  border: 1px solid #5fd7fd;
  box-shadow: 0 1px 2px #bbb;
  margin: 0 8px;
  margin-top: 10px;
  overflow: hidden;

  .model-content {
    padding: 8px 8px 0 8px;
  }

  .model-title {
    background: url("~@/assets/ba_imgs/box-title-bg.png") no-repeat center / 100% 100%;
    padding: 0 12px;
    display: flex;
    align-items: center;
    height: 34px;
  }
}
</style>
